"use client";

import { useAuth } from "@/state/AuthContexts";
import {
  Button,
  CheckboxInput,
  Icon,
  InlineAlert,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  TextButton,
  TextInput,
} from "@repo/ui";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { loginSchema, validateAndSetErrors } from "../../../shared/schemas";
import PWADownload from "../components/pwaDownload";

interface ErrorT {
  email?: string;
  other?: string;
}

export default function LoginPage() {
  const { login } = useAuth();
  const router = useRouter();
  const searchParams = useSearchParams();
  const token = searchParams?.get("token");
  const invoiceId = searchParams?.get("invoiceId");

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorT>();
  const [loggedIn, setLoggedIn] = useState(false);
  const [mfa, setMFA] = useState<{
    session: string;
    username: string;
    code: string;
  }>({ session: "", username: "", code: "" });

  useEffect(() => {
    const qState = localStorage.getItem("q-state");
    if (qState === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    const email = (emailRef.current?.value || "").trim().toLowerCase();
    const password = passwordRef.current?.value || "";
    const hasError = await validateAndSetErrors(
      loginSchema,
      { email },
      setError
    );

    if (hasError) {
      setLoading(false);
      return;
    } else {
      setLoading(true);

      const response = await login(
        email.trim().toLowerCase(),
        password,
        token ?? "",
        invoiceId ? parseInt(invoiceId) : undefined
      );

      if (response && response.success) {
        if (response.session && response.username) {
          setMFA({
            ...mfa,
            session: response.session,
            username: response.username,
          });
          setError({ email: "", other: "" });
          setLoading(false);
        }
      } else if (response && !response?.success) {
        setError({ email: "", other: response?.message });
        setLoading(false);
      } else {
        setError({
          email: "",
          other: "Something went wrong. Please contact support.",
        });
        setLoading(false);
      }
    }
  };

  const handleMFA = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    setLoading(true);

    login("", "", "", null, mfa.username, mfa.code, mfa.session)
      .then((response) => {
        if (!response.success) throw new Error(response.message);
      })
      .catch((error) => setError({ email: "", other: error.message }))
      .finally(() => setLoading(false));
  };

  const handleEmailChange = () => {
    setError({ email: "", other: "" });
  };

  // This is just in case a user navigates to /login but is actually logged in
  if (loggedIn) {
    router.push("/invoices");
    return null;
  }

  return (
    <>
      <div className="Login flex min-h-[90vh] w-full flex-col items-center justify-between">
        <div className="block" />
        <div className="w-full max-w-sm" data-testid="login-header">
          <div className="mb-5 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <Icon icon="bolt-gradient" size="32" />
              <span className="font-gilroy ml-3 text-2xl font-bold text-gray-900">
                Quickly
              </span>
            </div>
            <TextButton
              label="Open an account"
              icon="arrow-up-right"
              iconRight
              onClick={() =>
                router.push(
                  `/signup${
                    token ? `?token=${token}&invoiceId=${invoiceId}` : ""
                  }`
                )
              }
            />
          </div>
          <div className="font-gilroy mb-5 text-2xl font-bold text-gray-900">
            Log in
          </div>
          {!mfa?.session ? (
            <form className="mb-5 space-y-5" onSubmit={handleLogin}>
              <TextInput
                data-testid="login-email-input"
                label="Email"
                onChange={handleEmailChange}
                autoComplete="email"
                error={error?.email}
                ref={emailRef}
                wide
              />
              <TextInput
                data-testid="login-password-input"
                label="Password"
                ref={passwordRef}
                autoComplete="current-password"
                password
                required
                wide
              />
              {Boolean(error?.other) && (
                <div className="mt-6">
                  <InlineAlert
                    title="Login error"
                    subtitle={error?.other}
                    type="error"
                  />
                </div>
              )}

              <div className="mb-5 flex justify-between">
                <CheckboxInput
                  checked={remember}
                  onCheckedChange={() => setRemember((s) => !s)}
                  label="Remember me"
                />
                <TextButton
                  label="Forgot password?"
                  onClick={() => {
                    router.push("/forgot");
                  }}
                />
              </div>
              <div className="flex justify-end pt-5">
                <Button
                  data-testid="login-submit-button"
                  label="Log in"
                  loading={loading}
                  submit
                  color="gradient"
                />
              </div>
            </form>
          ) : (
            <form className="mt-5 flex flex-col gap-5" onSubmit={handleMFA}>
              <div className="space-y-3">
                <div className="text-sm font-semibold">
                  Enter the OTP code from your authenticator app
                </div>
                <InputOTP
                  maxLength={6}
                  value={mfa?.code}
                  autoFocus={true}
                  onChange={(value) => setMFA({ ...mfa, code: value })}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
              {Boolean(error?.other) && (
                <InlineAlert type="error" title={error?.other} />
              )}
              <div className="">
                <Button
                  disabled={loading}
                  loading={loading}
                  submit
                  label="Sign in"
                />
              </div>
            </form>
          )}
        </div>
        <div className="flex justify-center gap-5">
          <Link
            aria-label="privacy-policy"
            href="https://helloquickly.com/privacy-policy"
            className="text-sm font-medium hover:cursor-pointer hover:underline"
            target="_blank"
          >
            Privacy
          </Link>
          <Link
            className="text-sm font-medium hover:cursor-pointer hover:underline"
            href="https://helloquickly.com/terms-of-service"
            target="_blank"
            aria-label="terms-of-service"
          >
            Terms
          </Link>
          <PWADownload />
        </div>
      </div>
    </>
  );
}
