"use client";

import { useEffect, useState } from "react";

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export default function PWADownload() {
  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    function handleInstallPrompt(event: IBeforeInstallPromptEvent) {
      event.preventDefault();
      setPrompt(event);
    }

    const width = window.innerWidth;
    const isMobile = 700 > width;

    window &&
      !isMobile &&
      window.addEventListener(
        "beforeinstallprompt",
        handleInstallPrompt as unknown as EventListener
      );

    return () => {
      window &&
        window.removeEventListener(
          "beforeinstallprompt",
          handleInstallPrompt as unknown as EventListener,
          true
        );
    };
  }, [prompt]);

  const triggerDownload = () => {
    prompt?.prompt();
  };

  return (
    <a
      className={`text-sm font-medium hover:cursor-pointer hover:underline ${!prompt ? "hidden" : ""}`}
      onClick={triggerDownload}
    >
      Download Quickly
    </a>
  );
}
